"use client";
import React from "react";
import { type IOpenSourceProject } from "../../types/types";
import { motion } from "framer-motion";
import Image from "next/image";
import { LinkTrackers } from "~/components/reusables/link-trackers";

const AnimatedCard = ({
  title,
  description,
  img,
  link,
}: IOpenSourceProject) => {
  return (
    <LinkTrackers
      segmentMsg="Open Source Project Clicked"
      segmentOpt={{ project_name: title }}
      href={link}
      target='_blank'
      legacyBehavior
    >
      <motion.div
        className="min-h-full overflow-hidden rounded  bg-white p-2 shadow-xl"
        whileHover={{
          scale: 1.01,
          boxShadow: "0px 4px 24px rgba(27, 28, 29, 0.2)",
          transition: { duration: 0.25, ease: "easeInOut" },
        }}
      >
        <div className="relative h-[100px]">
          <Image
            unoptimized
            src={`/images/pages/opensource/projects/${img}`}
            alt={title}
            fill
            className="object-contain"
          />
        </div>
        <div className="px-6 py-4">
          <div className="mb-2 text-center text-xl font-bold">
            {title.length < 60 ? title : `${title.slice(0, 60)}...`}
          </div>
          <p className="text-base text-gray-700">{description}</p>
        </div>
      </motion.div>
    </LinkTrackers>
  );
};

export default AnimatedCard;
